import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { getCookieByName } from '../../helpers/getCookieByName'
import loader from '../../resources/images/spinner.gif'
import BoxFormStart from '../forms/BoxFormStart'

const TabTwo = ({ disabledButton }) => {
    const [loadingData, setLoadingData] = useState({
        loading: false,
        lambdaURL: '',
    })

    const { loading, lambdaURL } = loadingData

    useEffect(() => {
        if (location.href.includes('https://www.dev.lenses.io/')) {
            setLoadingData({
                ...loadingData,
                lambdaURL:
                    'https://384in5y2mk.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads',
            })
        } else {
            setLoadingData({
                ...loadingData,
                lambdaURL:
                    'https://s1qhfrb27e.execute-api.eu-west-1.amazonaws.com/prod/api/v1/leads',
            })
        }
    }, [])

    const handleFormSubmitBox = () => {
        document.querySelector('#splunk_id').value = getCookieByName('mintjs%3Auuid')
        document.querySelector('#timestamp').value = new Date().toLocaleString()
        let form = document.getElementById('box-form')
        let isValid = form.reportValidity()
        if (isValid) {
            document.querySelector('#formsubmitBox').disabled = true
            sessionStorage.setItem('DownloadedKey', 'true')
            setLoadingData({
                ...loadingData,
                loading: true,
            })

            let emailValue = document.querySelector('#email').value

            axios
                .post(`${lambdaURL}`, {
                    email: emailValue,
                    source: 'website',
                    company: 'Not provided',
                    license: 'box',
                })
                .then(function (res) {
                    const data = res.data
                    sessionStorage.setItem('id', data.identifier)
                    document.querySelector('#license').value = data.identifier
                    document.querySelector('#downloadURL').value = data.downloadURL
                    form.submit()
                })
                .catch(function (err) {
                    console.log(err)
                    window.location.href = '/backend-error/'
                })
        }
    }

    return (
        <div className='tab-container'>
            {loading && (
                <section className='pt-5'>
                    <div className='text-center primary-text fw-900 f-20'>Please wait...</div>
                    <div className='text-center pb-5 justify-content my-5'>
                        <img
                            className=' pb-5'
                            src={loader}
                            alt='spinner'
                            style={{ marginBottom: '2000px' }}
                        />
                    </div>
                </section>
            )}
            <Fragment>
                <div className='d-flex flex-wrap align-items-center justify-content-center'>
                    <div className='col-6 col-md-6 col-12 pr-5'>
                        <h2 className='f-24'>Docker run a whole Kafka environment</h2>
                        <p>
                            Completely free localhost Kafka Docker packaged with a full Developer
                            Experience
                        </p>
                        <ul>
                            <li>Single broker Kafka v 3.3</li>
                            <li>Kafka Connect, Schema Registry & more</li>
                            <li>Full version of Lenses</li>
                            <li>Sample streaming data</li>
                        </ul>
                        <BoxFormStart />
                    </div>
                    <div className='col-6 col-md-6 col-12 mobile-margin-top mb-5'>
                        <div className='cloud-aws-msk-video p-unset-i'>
                            <iframe
                                src='//player.vimeo.com/video/624320966'
                                // data-src="//player.vimeo.com/video/624320966"
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                }}
                                frameBorder='0'
                                allow='autoplay; fullscreen'
                                allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button
                            id='formsubmitBox'
                            className='bg-red text-white  br-21 monitoring-guide-cta-padding border-0 px-5'
                            onClick={handleFormSubmitBox}
                            disabled={disabledButton}>
                            Get Started
                        </button>
                    </div>
                </div>
            </Fragment>
        </div>
    )
}

export default TabTwo

TabTwo.propTypes = {
    disabledButton: PropTypes.bool,
}
