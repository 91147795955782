import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../components/partials/HeaderSEO'
import Spacing from '../components/partials/Spacing'
import TabOne from '../components/start/TabOne'
import TabTwo from '../components/start/TabTwo'
import TabThree from '../components/start/TabThree'

import { getClientId } from '../helpers/getClientId'
import { getCountry } from '../helpers/getCountry'
import { initializeVideos } from '../helpers/initializeVideo'
import axios from 'axios'

import { StaticImage } from 'gatsby-plugin-image'

import { navbarScrolling } from '../helpers/navbarScrolling'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

declare const window: any

interface ResponseData {
    response: Response
}
interface Response {
    score: number
}

const start = () => {
    const data = useStaticQuery(graphql`
        query StartSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "WizardForm" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node
    const [tabIndex, setTabIndex] = useState(0)
    const [disabledButton, setDisabledButton] = useState(true)
    // Extra control to prevent enabling button without having triggered recaptcha
    // Needed to prevent non-professional email submission
    let [passedRecaptcha, setPassedRecaptcha] = useState(false)

    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute('6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF', {
                    action: 'verify',
                })
                .then((token) => {
                    try {
                        axios
                            .post(
                                `https://m03u65lddf.execute-api.eu-west-2.amazonaws.com/reCaptchaWebsiteScore?token=${token}`,
                            )
                            .then(function (res) {
                                const data = res.data as ResponseData
                                let score = data.response.score
                                if (score > 0.8) {
                                    // pass
                                    setDisabledButton(false)
                                    setPassedRecaptcha(true)
                                }
                            })
                    } catch (error) {
                        console.log(error)
                    }
                })
        })
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement('script')
        script.src =
            'https://www.google.com/recaptcha/api.js?render=6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
        script.addEventListener('load', handleLoaded)
        document.body.appendChild(script)

        try {
            // Getting clientId
            const interval = setInterval(function () {
                if (getClientId()) {
                    if (document.querySelector<HTMLInputElement>('#ga_client_id')) {
                        let gaClientId = getClientId()
                        document.querySelector<HTMLInputElement>('#ga_client_id').value = gaClientId
                        clearInterval(interval)
                    }
                }
            }, 1000)
            // Getting country name for routing
            getCountry()
        } catch (error) {
            console.log(error)
        }
        navbarScrolling()
    }, [])

    useEffect(() => {
        // initializeVideos
        initializeVideos()
    }, [tabIndex])

    return (
        <Layout newsletter={false} linkColor={'dark'} isTransparent={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <Helmet>
                <link rel='canonical' href='https://lenses.io/start/' />
            </Helmet>
            <section>
                <div className='hero-margin container-1'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm-8 col-12 text-center'>
                            <h1>Lenses for any Kafka</h1>
                            <p className='pt-4'>Three options to trial Lenses for free</p>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section className='mobile-margin-top'>
                <div className='container-1'>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList>
                            <Tab>Developer Experience for every Kafka</Tab>
                            <Tab>Lenses in a Kafka Docker</Tab>
                            <Tab style={{ width: '33%' }}>Enterprise Kafka Connectors</Tab>
                            {/* <Tab>Lenses for my AWS MSK</Tab> */}
                        </TabList>
                        <TabPanel>
                            <TabOne
                                disabledButton={disabledButton}
                                passedRecaptcha={passedRecaptcha}
                            />
                        </TabPanel>
                        <TabPanel>
                            <TabTwo disabledButton={disabledButton} />
                        </TabPanel>
                        <TabPanel forceRender>
                            <TabThree />
                        </TabPanel>
                        {/* <TabPanel>
              <div className="tab-container">
                <div className="d-flex flex-wrap">
                  <div className="col-md-6 col-12">
                    <h2 className="f-24">Deploy Lenses via AWS marketplace</h2>
                    <p>Launch Lenses for AWS MSK from AWS marketplace:</p>
                    <ul>
                      <li>VPC support</li>
                      <li>CloudWatch log group</li>
                      <li>IAM Role and Security Group</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-12 ">
                    <img className="img-fluid" src={aws} alt="AWS" />
                  </div>
                </div>
                <div className="text-center mt-5 pt-5">
                  <a
                    id="AWS-start"
                    className="bg-red text-white  br-21 py-15 border-0 px-5 cursor-pointer"
                    href="https://aws.amazon.com/marketplace/pp/prodview-dai3bz77xloxi"
                  >
                    More Info
                  </a>
                </div>
              </div>
            </TabPanel> */}
                    </Tabs>
                </div>
            </section>

            <Spacing />
            <section className='mobile-margin-top mobile-margin-bottom mobile-padding-top'>
                <div className='container-1'>
                    <h3 className='h2-like text-center'> Our users say </h3>
                    <div className='d-flex flex-wrap align-items-center pt-1 mt-1'>
                        <div className='col-md-5'>
                            <div>
                                <StaticImage
                                    className='img-fluid mx-auto d-block mt-5'
                                    src='../resources/images/best_rated_realtime_dataops_platform.png'
                                    placeholder='tracedSVG'
                                    style={{ maxWidth: '300px' }}
                                    alt='Lenses - data flows'
                                />
                            </div>
                        </div>

                        <div className='col-md-6 offset-md-1 mt-5'>
                            <p className='number-format'>DataOps excellence</p>
                            <h2 className='h1-like pt-2 pb-1'>
                                <small>Satisfaction</small> 100%
                            </h2>
                            <div className='pr-3'>
                                <p className='hero-description-dark pr-5'>
                                    Lenses is the highest rated product for real-time stream
                                    analytics according to independent third party reviews.
                                </p>
                                <a
                                    className='primary-text pt-0'
                                    id='lenses-reviews'
                                    href='https://www.g2.com/products/lenses/reviews'
                                    rel='nofollow noopener noreferrer'
                                    target='_blank'
                                    role='button'>
                                    Read more reviews <i className='red-pointer-right'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Spacing />
        </Layout>
    )
}
export default start
