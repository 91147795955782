import React from 'react'

const BoxFormStart = () => {
    return (
        <form
            id='box-form'
            action='https://go.pardot.com/l/877242/2021-05-13/5nrxcq'
            acceptCharset='UTF-8'
            method='POST'
            className='pt-3'>
            <input
                name='Email'
                autoComplete='email'
                className='f-13 ml-2 w-100 email-form-box'
                id='email'
                placeholder='Your Email'
                required
            />
            {/* License */}
            <input name='License' id='license' className='d-none' />
            <input name='downloadURL' id='downloadURL' className='d-none' />

            <input name='Timestamp' id='timestamp' className='d-none' />

            <input name='GA Client ID' type='hidden' className='d-none' id='ga_client_id' />

            <input type='hidden' name='Splunk ID' id='splunk_id' className='d-none' />

            <div className='m-2 mr-4 f-12'>
                <label className='d-none' htmlFor='Terms Of Use'>
                    {' '}
                    Terms of use and Privacy Policy
                </label>
                <input type='checkbox' name='Terms Of Use' id='terms_of_use' required />{' '}
                <span className='pl-1'>
                    By submitting the form, you agree to our{' '}
                    <a className='link-text f-12' href='/legals/terms-and-conditions/'>
                        Terms of Use{' '}
                    </a>
                    and{' '}
                    <a className='link-text f-12' href='/legals/policy/'>
                        Privacy Policy
                    </a>
                </span>
            </div>
        </form>
    )
}

export default BoxFormStart
