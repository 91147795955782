import React, { useState, useEffect, Fragment } from 'react'
import TrialFormStart from '../forms/TrialFormStart'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { getCookieByName } from '../../helpers/getCookieByName'
import loader from '../../resources/images/spinner.gif'
import * as CompanyEmailValidator from 'company-email-validator'

const TabOne = ({ disabledButton, passedRecaptcha }) => {
    const [loadingData, setLoadingData] = useState({
        loading: false,
        lambdaURL: '',
    })

    const { loading, lambdaURL } = loadingData

    useEffect(() => {
        if (location.href.includes('https://www.dev.lenses.io/')) {
            setLoadingData({
                ...loadingData,
                lambdaURL:
                    'https://384in5y2mk.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads',
            })
        } else {
            setLoadingData({
                ...loadingData,
                lambdaURL:
                    'https://s1qhfrb27e.execute-api.eu-west-1.amazonaws.com/prod/api/v1/leads',
            })
        }
    }, [])

    const handleFormSubmit = () => {
        // Update Name selectors to submit value from input

        // With no need to create extra field to map to CRM
        if (document.querySelector('#trial_source_final')) {
            document.getElementById('trial_source').name = ''
            document.querySelector('#trial_source_final').name = 'Trial Source'
        }

        document.querySelector('#splunk_id').value = getCookieByName('mintjs%3Auuid')
        document.querySelector('#timestamp').value = new Date().toLocaleString()
        let form = document.getElementById('trial-form')
        let isValid = form.reportValidity()
        if (isValid) {
            document.querySelector('#formsubmit').disabled = true
            sessionStorage.setItem('DownloadedKey', 'true')
            setLoadingData({
                ...loadingData,
                loading: true,
            })
            let emailValue = document.querySelector('#email').value

            axios
                .post(`${lambdaURL}`, {
                    email: emailValue,
                    source: 'website',
                    company: 'Not provided',
                    license: 'trial',
                })
                .then(function (res) {
                    const data = res.data
                    sessionStorage.setItem('id', data.identifier)
                    document.querySelector('#license').value = data.identifier
                    document.querySelector('#downloadURL').value = data.downloadURL
                    form.submit()
                })
                .catch(function (err) {
                    console.log(err)
                    window.location.href = '/backend-error/'
                })
        }
    }

    const validateEmailFlag = true

    const validateEmail = () => {
        const email = document.querySelector('#email')
        if (!CompanyEmailValidator.isCompanyEmail(email.value)) {
            email.classList.add('is-invalid')
            document.querySelector('#formsubmit').disabled = true
        } else {
            email.classList.remove('is-invalid')
            if (passedRecaptcha === true) {
                document.querySelector('#formsubmit').disabled = false
            }
        }
    }

    const reCAPTCHATriggered = () => {
        // On Business Emails
        if (validateEmailFlag) {
            try {
                document.querySelector('#formsubmit').disabled = false
                passedRecaptcha = true
                validateEmail()
            } catch (error) {
                console.log(error)
            }
            // Default Behaviour
        } else {
            document.querySelector('#formsubmit').disabled = false
        }
    }

    return (
        <div className='tab-container'>
            {loading && (
                <section className='pt-5'>
                    <div className='text-center primary-text fw-900 f-20'>Please wait...</div>
                    <div className='text-center pb-5 justify-content my-5'>
                        <img
                            className=' pb-5'
                            src={loader}
                            alt='spinner'
                            style={{ marginBottom: '1000px' }}
                        />
                    </div>
                </section>
            )}
            <div className='d-flex flex-wrap justify-content-center'>
                <Fragment>
                    <div className='col-md-6 col-12'>
                        <h2 className='f-24'>Self-installation wherever your Kafka lives</h2>
                        <p>A full Developer Experience for your Kafka:</p>
                        <ul>
                            <li>Connects to any Kafka</li>
                            <li>Setup in minutes</li>
                            <li>Deployed via Helm, Docker, Linux archive or AWS Marketplace</li>
                        </ul>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='footer-color p-4'>
                            <TrialFormStart validateEmail={validateEmail} />
                        </div>
                    </div>
                </Fragment>
            </div>
            <div className='text-center'>
                {/* Recaptcha */}
                {/* Trigger V2 only on lower scores */}
                {disabledButton && (
                    <ReCAPTCHA
                        sitekey='6LfNhSEUAAAAAL9OJM7RO856GguxnnKDM-uveavH'
                        onChange={reCAPTCHATriggered}
                    />
                )}
                <button
                    id='formsubmit'
                    className='mt-5 bg-red text-white  br-21 monitoring-guide-cta-padding border-0 px-5'
                    onClick={handleFormSubmit}
                    disabled={disabledButton}>
                    Free trial
                </button>
            </div>
        </div>
    )
}

export default TabOne

TabOne.propTypes = {
    disabledButton: PropTypes.bool,
    passedRecaptcha: PropTypes.bool,
}
