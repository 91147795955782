import React from 'react'
import PropTypes from 'prop-types'

const TrialFormStart = ({ validateEmail }) => {
    const changedDropdownOption = (e) => {
        //  Filtering for other value
        if (e.target.value === 'other') {
            // Create an <input> element, set its type, name, id
            // Classname and required attributes
            var input = document.createElement('input')
            input.type = 'text'
            input.id = 'trial_source_final'
            input.required = true
            input.placeholder = 'Specify other'
            input.classList.add(
                'footer-color',
                'w-100',
                'pl-2',
                'input-form-border',
                'pb-2',
                'placeholder-opacity',
                'my-2',
            )

            // Place to insert element
            var termsOfUse = document.querySelector('.select-option-trial')
            termsOfUse.parentNode.insertBefore(input, termsOfUse.nextSibling)
        } else {
            if (document.querySelector('#trial_source_final')) {
                // If exists remove
                document.querySelector('#trial_source_final').remove()
            }
        }
    }

    return (
        <form
            id='trial-form'
            action='https://go.pardot.com/l/877242/2022-01-17/9d8pyr'
            acceptCharset='UTF-8'
            method='POST'>
            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='text'
                required
                id='first_name'
                maxLength={80}
                name='First Name'
                placeholder='First Name*'
            />
            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='text'
                required
                id='last_name'
                maxLength={80}
                name='Last Name'
                placeholder='Last Name*'
            />
            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='email'
                required
                id='email'
                maxLength={100}
                name='Email'
                placeholder='Email*'
                onChange={validateEmail}
            />
            {validateEmail && (
                <div className='invalid-feedback ml-2'>Please provide your professional email</div>
            )}

            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='text'
                maxLength={100}
                id='company'
                name='Company'
                placeholder='Company'
            />

            <select
                name='Trial Source'
                className='select-option-trial'
                id='trial_source'
                required
                onChange={(e) => changedDropdownOption(e)}
                defaultValue={''}>
                <option value='' disabled>
                    Deployment Option
                </option>
                <option value='aws-msk'>AWS MSK</option>
                <option value='confluent-cloud'>Confluent Cloud</option>
                <option value='confluent-Platform'>Confluent Platform</option>
                <option value='aiven'>Aiven</option>
                <option value='azure-hd-insight'>Azure HDInsight</option>
                <option value='instaclustr'>Instaclustr</option>
                <option value='redpanda'>Redpanda</option>
                <option value='strimzi'>Strimzi</option>
                <option value='cloudera'>Cloudera</option>
                <option value='openshift'>Openshift Kafka</option>
                <option value='ibm-event-streams'>IBM Event Streams</option>
                <option value='opensource-kafka'>Other Open Source Kafka</option>
                <option value='other'>Other</option>
            </select>

            <input name='Timestamp' id='timestamp' className='d-none' />
            <input name='License' id='license' className='d-none' />
            <input name='downloadURL' id='downloadURL' className='d-none' />
            <input name='Expiration Date' id='expiration_date' className='d-none' />

            <input name='GA Client ID' type='hidden' className='d-none' id='ga_client_id' />

            <input type='hidden' name='Splunk ID' id='splunk_id' className='d-none' />

            <input type='text' className='d-none' id='country_name' name='Country Name' />
            <div className='m-2 mb-4'>
                <label className='d-none' htmlFor='Terms Of Use'>
                    {' '}
                    Terms of use and Privacy Policy
                </label>
                <input type='checkbox' id='terms_of_use' name='Terms Of Use' required />{' '}
                <span className='pl-1'>
                    By submitting the form, you agree to our{' '}
                    <a className='link-text' href='/legals/terms-and-conditions/'>
                        Terms of Use{' '}
                    </a>
                    ,{' '}
                    <a className='link-text' href='/legals/policy/'>
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a className='link-text' href='/legals/sela/'>
                        SELA
                    </a>
                </span>
            </div>
            <div
                className='g-recaptcha'
                data-sitekey='6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
                data-size='invisible'></div>
        </form>
    )
}

export default TrialFormStart

TrialFormStart.propTypes = {
    validateEmail: PropTypes.func,
}
