import React, { Fragment, useState } from 'react'
import { navigate } from '@reach/router'
import EnterpriseSupportForm from '../forms/EnterpriseForm'
// import connectorImage from '*.jpg'
// import start_rocket from '../../resources/images/start/start-rocket.svg'
// import EnterpriseForm from 'src/components/forms/EnterpriseForm'

const TabThree = () => {
    const [contactState] = useState({
        pardotHandler: 'https://go.pardot.com/l/877242/2024-02-20/syqhq1',
        headingTitle: 'Enterprise Support',
        displayParagraph: "We're here to help",
        // image: connectorImage,
        messagePlaceholder: 'Tell us about your use case',
        contactSource: 'NONE',
        validateEmailFlag: false,
    })

    const { pardotHandler, messagePlaceholder, contactSource, validateEmailFlag } = contactState
    const handleFormSubmit = (connector) => {
        navigate(`/enterprise-support-ok/?connector=${connector}`)
        // document.querySelector<HTMLInputElement>('#splunk_id').value =
        //     getCookieByName('mintjs%3Auuid')
        // document.querySelector<HTMLInputElement>('#timestamp').value = new Date().toLocaleString()
        // document.querySelector<HTMLInputElement>('#zoominfo-default-form').disabled = true
    }

    return (
        <div className='tab-container d-flex'>
            <Fragment>
                <div className='d-flex flex-wrap  justify-content-center'>
                    <div className='col-6 col-md-6 col-12 pr-5'>
                        <h2 className='f-24 pr-3'>
                            Open-source Kafka Connectors, enterprise support
                        </h2>
                        <p>Try out our Apache 2 licensed source and sink Kafka Connectors: </p>
                        <ul>
                            <li>Prioritized feature requests</li>
                            <li>24/7 support and incident response</li>
                            <li>
                                A first class Developer Experience when deployed through Lenses.{' '}
                            </li>
                        </ul>
                    </div>
                    <div className='col-6 col-md-6 col-12 mobile-margin-top text-center'>
                        <div className='footer-color p-4'>
                            <EnterpriseSupportForm
                                formId='enterprise-form'
                                onSubmit={handleFormSubmit}
                                action={pardotHandler}
                                inputId='formsubmit'
                                inputValue='Send Now'
                                firstName={'required'}
                                lastName={'required'}
                                email={'required'}
                                company={'notRequired'}
                                message={'notRequired'}
                                messageValue={messagePlaceholder}
                                type='contactUs'
                                contactSource={contactSource}
                                validateEmailFlag={validateEmailFlag}
                                connectorsField={true}
                                btnText={'Request Trial'}
                            />
                        </div>
                        {/*<a className='text-center' href='https://portal.lenses.io/register/'>*/}
                        {/*    <img*/}
                        {/*        className='img-fluid mx-auto '*/}
                        {/*        width={170}*/}
                        {/*        height={170}*/}
                        {/*        src={start_rocket}*/}
                        {/*        alt='Promo asset'*/}
                        {/*    />*/}
                        {/*    <p className='mt-2 mb-0 fw-900 f-16'>Quickstart</p>*/}
                        {/*    <p className='f-14 pt-0'>Free demo environment</p>*/}
                        {/*</a>*/}
                    </div>
                    {/*<div className='text-center'>*/}
                    {/*    <a*/}
                    {/*        href='https://portal.lenses.io/register/'*/}
                    {/*        className='bg-red text-white  br-21 monitoring-guide-cta-padding border-0 px-5'>*/}
                    {/*        Start Now*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </Fragment>
        </div>
    )
}

export default TabThree
